<template>
    <div class="image-container">
        <img :id="id" v-if="src !== null" :src="src"> 
        <!-- <img :id="id" v-if="src !== null" :src="src" @click="$emit('click')" @load="$emit('load')">  -->
        <!-- <i class="material-icons danger"> delete_forever </i>    -->
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../services/auth-header';
export default {
    name: 'EventImage',
    props: {
        filename: String,
        client_id: String,
        claim_id: String,
        id: String
    },
    data(){
        return {
            src: null,
        }
    },
    mounted() {
        return axios.get(`https://api.varsanpr.com/api/claims/${this.claim_id}/evidence/${this.filename}?client_id=${this.client_id}`, {
            responseType: 'arraybuffer',
            headers: authHeader()
        })
        .then(response => {
            this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
        })
        .catch(error => {
            return null;
        });

    },
    methods: {
          loadImage(){
              return axios.get(`https://api.varsanpr.com/api/claims/${this.claim_id}/evidence/${this.filename}?client_id=${this.client_id}`, {
                  responseType: 'arraybuffer',
                  headers: authHeader()
              })
              .then(response => {
                  this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
              })
              .catch(error => {
                    return null;
              });
          }
    },
    watch: {
        filename: function(val){
            this.loadImage();
        },
        site_id: function(val){
            this.loadImage();
        },
        endpoint: function(val){
            this.loadImage();
        }
    }
}
</script>

<style scoped>

.image-container {
    position: relative;
    width: 100% !important;
    height:auto !important;
    margin: 5px;
}

.image-container img {
    width: 100%;
    height: 100%;
    position: relative;
}

i {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    margin: 5px;
}

</style>