<template>
    <div class="row">
        <div class="col-12">
            <div class="page-header">
                <div class="header-sub-container">
                    <!-- Page Heading -->
                    <h1 class="page-heading"> {{ $t('manage_evidence.manage_claim') }} </h1>
                    <h4 class="heading-4">{{ $t('claims.processing.reference-number') }} - 
                        <div v-if="loading" class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <span v-else> {{data.reference}} </span> 
                        </h4>
                </div>
                <div class="header-sub-container">
                    <!-- Header Buttons -->
                    <button v-if="$can('claims-process')" class="btn btn-danger-secondary" @click="() => cancelClaimModal.show()"> Cancel </button>
                    <!-- Vertically centered modal -->
                    <!-- <div class="modal-dialog modal-dialog-centered" style="display:none;" ref="cancel_modal">
                        
                    </div> -->
                    <button v-if="$can('claims-process')" class="btn btn-primary" @click="processClaim"> Accept Current Evidence </button>
                </div>
            </div>

            <!-- Missing evidence alert message -->
            <div class="alert alert-custom alert-indicator-left indicator-danger m-b-xxl" role="alert" v-if="data.vehicleCount < 1 || data.tillCount < 1">
                <div class="alert-content" v-if="data.vehicleCount < 1 && data.tillCount < 1">
                    <span class="alert-title"> {{ $t('claims.new.very_important')}}</span>
                    <span class="alert-text danger"> {{ $t('manage_evidence.missing_both' )}}</span>
                    <span class="alert-text"> {{ $t('manage_evidence.upload_evidences')}}</span>
                </div>
                <div class="alert-content" v-else>
                    <span class="alert-title"> {{ $t('claims.new.comments') }} </span>
                    <span class="alert-text danger" v-if="data.tillCount < 1"> {{ $t('manage_evidence.missing_till_receipt') }} </span>
                    <span class="alert-text danger" v-if="data.vehicleCount < 1" > {{ $t('manage_evidence.missing_vehicle_image')}} </span>
                    <span class="alert-text"> {{ $t('manage_evidence.upload_evidences')}}</span>
                </div>
            </div>
            <!-- Ready to Process alert message  -->
            <div v-else class="alert alert-custom alert-indicator-left indicator-info m-b-xxl">
                <div class="alert-content">
                    <span class="alert-title"> {{ $t('claims.new.comments')}} </span>
                    <span class="alert-text"> {{ $t('manage_evidence.ready_to_process')}} </span>
                </div>
            </div>

            <div class="row">
                <!-- Claim information -->
                <div class="col-12 col-xl-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title"> {{$t('manage_evidence.claim_details')}} </h5>
                            <hr class="card-hr">
                            <div v-if="loading" class="flex-center">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden" style="margin: auto;">Loading...</span>
                                </div>
                            </div>
                            <div v-else class="main-container">
                                <div class="sub-container">
                                    <span> {{$t('manage_evidence.labels.fuel')}} </span>
                                    <span> {{$t('manage_evidence.labels.liters')}} </span>
                                    <span> {{$t('manage_evidence.labels.value')}} </span>
                                    <span> {{$t('manage_evidence.labels.pump')}} </span>
                                    <span> {{$t('manage_evidence.labels.user')}} </span>
                                    <span> {{$t('manage_evidence.labels.site')}} </span>
                                    <span> {{$t('manage_evidence.labels.timestamp')}} </span>
                                    <span> {{$t('manage_evidence.labels.status')}} </span>
                                </div>
                                <div class="sub-container right">
                                    <span v-if="data.fuel === 'Petrol'"> {{$t('claims.new.petrol')}} </span>
                                    <span v-if="data.fuel === 'Diesel'"> {{$t('claims.new.diesel')}} </span>
                                    <span> {{ data.litres }} </span>
                                    <span> £ {{ Number.parseFloat(data.value).toFixed(2) }} </span>
                                    <span> {{ data.pump }} </span>
                                    <span> {{ data.user }} </span>
                                    <span> {{ data.site }} </span>
                                    <span> {{ getTimestamp }} </span>
                                    <div v-if="data.tillCount < 1 || data.vehicleCount < 1" class="status-container-warning"> <span> {{ data.status}} </span> </div>
                                    <div v-else class="status-container-success"> <span> {{ $t('manage_evidence.status_ready_to_process')}} </span> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Vehicle Information -->
                <div class="col-12 col-xl-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title"> {{ $t('manage_evidence.vehicle_details')}} </h5>
                            <hr class="card-hr">
                            <div v-if="loading" class="flex-center">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden" style="margin: auto;">Loading...</span>
                                </div>
                            </div>
                            <div v-else class="main-container">
                                <div class="sub-container">
                                    <span> {{$t('manage_evidence.labels.plate')}} </span>
                                    <span> {{$t('global.make')}} </span>
                                    <span> {{$t('global.model')}} </span>
                                    <span> {{$t('global.colour')}} </span>
                                </div>
                                <div class="sub-container right">
                                    <div>
                                        <span class="vrm number-plate-gbr"> {{ data.plate }} </span>
                                    </div>
                                    <span> {{ data.vehicle_make}} </span>
                                    <span> {{ data.vehicle_model }} </span>
                                    <span> {{ data.vehicle_colour }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="sub-heading"> {{ $t('manage_evidence.upload_evidence')}} </h2>

            <div class="row">
                <!-- Vehicle Image Upload -->
                <div class="col-12 col-xl-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title"> {{ $t('manage_evidence.vehicle_image_upload')}} </h5>
                            <hr class="card-hr">
                            <p v-if="data.vehicleCount < 1" class="danger"> {{ $t('manage_evidence.missing_vehicle_image') }} </p>
                            <p v-else style="padding: 11px;"></p>
                            <div class="main-container">
                                <div class="sub-container width-30"> <span> {{$t('claims.new.vehicleImage')}} </span> </div>
                                <!-- <div class="sub-container right" id="evidence-upload-card"> -->
                                    <!-- <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="vehicle_image" @change="selectVehicleFile">
                                        <label for="" class="custom-file-label"></label>
                                    </div> -->
                                    <!-- <input type="file" id="vehicle_image" ref="vehicle_image" @change="selectVehicleFile" accept="image/png, image/jpeg"> -->
                                    <!-- <label for="vehicle_image"> {{ vehicleImage }} </label> -->
                                    <!-- <button @click="$refs.vehicle_image.click()"> Browse </button> -->
                                <!-- </div> -->


                                <div class="sub-container right">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" @change="selectVehicleFile">
                                        <label class="custom-file-label"> {{ file_name }}</label>
                                        <!-- <input type="file" class="custom-file-input" @change="selectVehicleFile" accept="image/png, image/jpeg" id="vehicle_image">
                                        <label class="custom-file-label"> {{file_name}} </label> -->
                                    </div>
                                </div>


                                <div class="image-preview" v-if="evidenceImages.vehicleDataURL !== null">
                                    <img :src="evidenceImages.vehicleDataURL" alt="Vehicle Image" class="image-preview__image">
                                    <div class="image-overlay">
                                        <button class="btn b-danger bg-danger text-secondary" @click="removeVehicleImage"> {{ $t('global.remove') }} </button>
                                        <button class="btn btn-primary" @click="uploadVehicleImage"> {{ $t('global.upload') }} </button>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Till Receipt Upload -->
                <div class="col-12 col-xl-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title"> {{ $t('manage_evidence.till_receipt_upload')}} </h5>
                            <hr class="card-hr">
                            <p v-if="data.tillCount < 1" class="danger"> {{ $t('manage_evidence.missing_till_receipt') }} </p>
                            <p v-else style="padding: 11px;"></p>
                            <div class="main-container">
                                <div class="sub-container width-30"> <span> {{$t('manage_evidence.till_receipt')}} </span> </div>
                                <div class="sub-container right">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" @change="selectReceiptFile">
                                        <label class="custom-file-label"> {{ file_name }}</label>
                                        <!-- <input type="file" class="custom-file-input" @change="selectVehicleFile" accept="image/png, image/jpeg" id="vehicle_image">
                                        <label class="custom-file-label"> {{file_name}} </label> -->
                                    </div>
                                </div>

                                <div class="image-preview" v-if="evidenceImages.tillReceiptDataURL !== null">
                                    <img :src="evidenceImages.tillReceiptDataURL" alt="Vehicle Image" class="image-preview__image">
                                    <div class="image-overlay">
                                        <button class="btn b-danger bg-danger text-secondary" @click="removeReceiptImage"> {{ $t('global.remove') }} </button>
                                        <button class="btn btn-primary" @click="uploadReceiptImage"> {{ $t('global.upload') }} </button>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="sub-heading"> {{ $t('manage_evidence.uploaded_evidence')}} </h2>
            <div class="card">
                <div class="card-body evidence-container">
                    <!-- <div v-for="asset in data.assets" :key="asset.id"> -->
                        <!-- <EventImage 
                            @click="openEventImage(event)"
                            v-if="event.image !== null"
                            class="widget-list-item-icon-image"
                            style="width: 150px; height: auto; cursor: pointer;"
                            :filename="event.image"
                            :site_id="event.site.id"
                            :id="'eventimage-' + event.id"                
                        /> -->
                        <ClaimImage 
                            v-for="asset in data.assets" 
                            :key="asset.id"
                            class="widget-list-item-icon-image"
                            style="width: 150px; height: auto; cursor: pointer;"
                            :filename="asset.filename"
                            :client_id="clientID"
                            :claim_id="id"
                            :id="'eventimage-' + asset.id"                
                        />
                    <!-- </div> -->
                </div>
            </div>

            <!-- Cancel Claim Modal -->
            <div class="modal fade" id="cancelClaimModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <!-- <div class="modal-header">
                            <h5 class="modal-title" id="successModalTitle">{{ $t('manage_evidence.provide_claim_cancellation_reason') }}</h5>
                        </div> -->
                        <!-- <div class="modal-body"> -->
                            <!-- <form action="submit">
                                <label for="">{{ $t('manage_evidence.provide_claim_cancellation_reason') }}</label>
                                <input type="text" required v-model="cancellationReason">
                                <div class="row">
                                    <button type="submit" @click="cancelClaim(cancellationReason)"> {{$t('manage_evidence.cancel_claim')}} </button>
                                </div>
                            </form> -->
                            <div class="modal-header">
                                <h5 class="modal-title"> {{$t('manage_evidence.cancel_claim').toUpperCase()}} </h5>
                            </div>
                            <div class="modal-body">
                                <Form @submit="(values) => cancelClaim(values)" :validation-schema="schema" class="form-container"> 
                                    <div class="">
                                        <label for="cancelClaim" class="m-b-xs"> {{ $t('manage_evidence.provide_claim_cancellation_reason') }} </label>
                                        <Field type="text" name="cancellationReason" id="cancelClaim" style="width: 100%;" class="form-control m-b-xs"/>
                                        <ErrorMessage name="cancellationReason" class="error-feedback"/>
                                    </div>
                                    <div class="flex-center m-t-xs">
                                        <button class="btn btn-primary" type="submit"> {{$t('manage_evidence.cancel_claim')}} </button>
                                    </div>
                                </Form>
                            </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import EvidenceService from '../../../services/evidences.service';
import ExternalServices from '../../../services/external.service';
import moment from 'moment';
import * as yup from 'yup';

// Components
import MessageCard from '../../../components/MessageCard.vue';
import ClaimImage from '../../../components/ClaimImage.vue';
import {Form, Field, ErrorMessage} from 'vee-validate';

export default {
    name: 'ManageEvidence',
    components: {MessageCard, ClaimImage, Form, Field, ErrorMessage},
    data() {
        const schema = yup.object().shape({
            cancellationReason: yup.string().min(3, this.$t('manage_evidence.valid_reason'))
        })
        return {
            loading: true,
            id: this.$route.params.id,
            data: {},
            evidenceImages: {
                vehicleImage: null,
                vehicleImageUploaded: false,
                vehicleDataURL: null,
                tillReceipt: null,
                tillReceiptUploaded: false,
                tillReceiptDataURL: null
            },
            clientID: this.$store.state.auth.user.selectedClient,
            cancelClaimModal: null,
            schema
            // eventImageModal: null,
            // receiptImageModal: null
        }
    },
    mounted() {
        this.loadData();
        //console.log(this.getAlertText());
        //this.fetchMMC();
        // this.eventImageModal = new bootstrap.Modal(document.getElementById('eventImageModal'));
        // this.receiptImageModal = new bootstrap.Modal(document.getElementById('receiptImageModal'));
        this.cancelClaimModal = new bootstrap.Modal(document.getElementById('cancelClaimModal'));
    },
    methods: {
        loadData() {
            this.loading = true;
            EvidenceService.getClaimData(this.id, this.clientID).then((response) => {
                this.data = response.data.claim;
                this.loading = false;
                this.fetchMMC();
            })
        },
        cancelClaim(values) {
            const reason = values.cancellationReason;
            if(reason) {
                EvidenceService.cancelClaim(reason, this.clientID, this.id)
                .then(() => {
                    this.data.status = 'Cancelled';
                    this.$success($t('manage_evidence.claim_cancelled_success'));
                    this.$router.push('/evidence');
                })
                .catch((error) => {
                    this.$error(this.$t('manage_evidence.failed_cancelling_claim'), error);
                })
            }
        },
        processClaim() {
            //Return if vehicle image or till receipt is missing 
            if(this.data.vehicleCount < 1 || this.data.tillCount < 1) {
                this.$error(this.$t('manage_evidence.missing_evidence'));
                return;
            }

            EvidenceService.allowProcessing(this.id, this.clientID)
            .then((response) => {
                this.data.status = 'Pending';
                this.$success(this.$t('manage_evidence.process_claim'));
                this.$router.push('/evidence');
            })
            .catch(() => {
                this.$error($t('manage_evidence.process_claim'));
            })
        },
        fetchMMC() {
            ExternalServices.getMMC(this.data.plate).then((response) => {
                this.data.vehicle_make = response.data.vehicle.make;
                this.data.vehicle_model = response.data.vehicle.model;
                this.data.vehicle_colour = response.data.vehicle.colour;
            }).catch(error => {
                console.log(error);
            })
        },
        selectVehicleFile(event) {
            this.evidenceImages.vehicleImage = event.target.files[0];

            //If a file was selected 
            if(this.evidenceImages.vehicleImage) {
                let reader = new FileReader();

                reader.onload = (event) => {
                    this.evidenceImages.vehicleDataURL = event.target.result;
                }
                reader.readAsDataURL(this.evidenceImages.vehicleImage);
            }
        },
        selectReceiptFile(event) {
            this.evidenceImages.tillReceipt = event.target.files[0];
            console.log(this.evidenceImages.tillReceipt.name);
            //If a file was selected
            if(this.evidenceImages.tillReceipt) {
                let reader = new FileReader();

                reader.onload = event => {
                    this.evidenceImages.tillReceiptDataURL = event.target.result;
                }
                reader.readAsDataURL(this.evidenceImages.tillReceipt);
            }
        },
        uploadVehicleImage() {
            this.evidenceImages.vehicleImageUploaded = true;
            const vehicleImage = this.evidenceImages.vehicleImage;

            if(vehicleImage === null) {
                this.$error(this.$t('manage_evidence.no_image_selected_error'));
                return;
            }
            const fd = new FormData();
            fd.append('file', vehicleImage, vehicleImage.name);
            fd.append('type', 'vehicle_image');
            fd.append('client_id', this.clientID);
            EvidenceService.uploadVehicleImage(this.id, fd)
            .then(() => {
                this.$success(this.$t('manage_evidence.vehicle_uploaded_successfully'));   //not working
                // this.$swal($t('manage_evidence.vehicle_uploaded_successfully'));
                this.evidenceImages.vehicleImageUploaded = false;
                this.evidenceImages.vehicleImage = null;
                this.evidenceImages.vehicleDataURL = null;
                // re-render page after uploading image 
                this.loadData();
            })
            .catch((error) => {
                console.log(error);
                this.$error(this.$t('manage_evidence.vehicle_uploaded_error'));
                this.evidenceImages.vehicleImageUploaded = false;
            });
        },
        uploadReceiptImage() {
            this.evidenceImages.tillReceiptUploaded = true;
            const receipt = this.evidenceImages.tillReceipt;

            if(receipt === null) {
                this.$error(this.$t('manage_evidence.receipt_uploaded_error'));
                return;
            }

            const fd = new FormData();
            fd.append('file', receipt, receipt.name);
            fd.append('type', 'till_receipt');
            fd.append('client_id', this.clientID);
            EvidenceService.uploadTillReceipt(this.id, fd)
            .then(() => {
                this.$success(this.$t('manage_evidence.receipt_uploaded_successfully'));
                this.evidenceImages.tillReceiptUploaded = false;
                this.evidenceImages.tillReceipt = null;
                this.evidenceImages.tillReceiptDataURL = null;
                this.loadData();
            })
            .catch(error => {
                console.log(error);
                this.$error(this.$t('manage_evidence.receipt_uploaded_error'));
                this.evidenceImages.tillReceiptUploaded = false;
            });
        },
        removeVehicleImage() {
            this.evidenceImages.vehicleDataURL = null;
            this.evidenceImages.vehicleImage = null;

        },
        removeReceiptImage() {
            this.evidenceImages.tillReceiptDataURL = null;
            this.evidenceImages.tillReceipt = null;
        }
    },
    computed:{
        getTimestamp: function() {
            if(this.data.incident_timestamp === undefined ) ""; 
            return moment(this.data.incident_timestamp, "YYYY-MM-DD HH:mm:ss.SSS").format("DD/MM/YYYY HH:mm:ss");
        },
        vehicleImage: function() {
            if(evidenceImages.vehicleImage === null) return "Select File";
        },
        file_name: function() {
            if(this.evidenceImages.vehicleImage == null){
                return "Select File";
            }
            if(this.evidenceImages.vehicleImage.name == null){
                return "File Selected";
            }
            return this.evidenceImages.vehicleImage.name;
        }
    }
}
</script>

<style scoped>

.heading-4 {
  font-size: 17px;
  font-weight: 400;
  padding: 5px 0;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.header-sub-container button{
    margin-left: 30px;
}

/* Card */

.card {
    /* border: 1px solid var(--stroke-color-primary); */
    position: relative;
}

.card .card-body .card-title {
    /* font-size: 18px; */
    /* font-weight: 300; */
}

.main-container {
    display: flex;
    flex-wrap: wrap;
}

.sub-container {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.sub-container.right {
    flex-grow: 1;
    justify-content: center;
}


.sub-container span {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 300;
}

.sub-container.right .vrm {
    width: auto;
    padding: 5px 10px;
}

#evidence-upload-card {
    display: inline;
    flex-direction: row;
}

#evidence-upload-card input {
    padding: 10px 0;
    border: none;
    background-color: #2B2B38;
    border-radius: 5px 0 0 5px;
    /* opacity: 0; */
    position: relative;
}

#evidence-upload-card button {
    background-color: #D0D0D0;
    border-radius: 0 5px 5px 0;
    padding: 12px;
    border: none;
    color: #3D3C3C;
    font-weight: 500;
}

.image-preview {
    position: relative;
    margin-top: 10px;
    width: 100%;
}

.image-preview__image {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.image-overlay:hover {
    opacity: 1;
}

.btn.btn-primary {
    margin: 0 20px;
}

.evidence-container {
    display: flex;
    flex-wrap: wrap;
}

.modal-body {
    padding-top: 0;
}

.form-container label {
    color: white;
    font-weight: 100;
}

</style>